<template>
  <AddShiftsModal
    @newShift="handleAddNewShift"
    :isEditingJob="false"
  ></AddShiftsModal>
  <LoadingSpinner v-if="loading"></LoadingSpinner>
  <div class="row" v-show="!loading">
    <div class="col-8">
      <ConfigureShiftsCard :isEditingTemplate="true"></ConfigureShiftsCard>
      <JobDetailsCard
        ref="details_card"
        :isEditingTemplate="true"
        @saveEditedTemplate="handleSave"
      ></JobDetailsCard>
    </div>
    <div class="col-4">
      <button class="btn btn-success btn-sm w-100 fs-5" @click="handleJobSave">
        Save template
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import JobDetailsCard from "@/components/jobs/Create/JobDetailsCard.vue";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import ConfigureShiftsCard from "@/components/jobs/Edit/ConfigureShifts.vue";
import { mapActions, mapGetters } from "vuex";
import AddShiftsModal from "@/components/jobs/Edit/AddNewShiftModal.vue";
import Swal from "sweetalert2";

export default defineComponent({
  name: "edit-job-template",
  components: {
    AddShiftsModal,
    JobDetailsCard,
    LoadingSpinner,
    ConfigureShiftsCard
  },
  methods: {
    ...mapActions("SingleJobTemplateModule", [
      "fetchSingle",
      "update",
      "resetState",
      "addNewShift"
    ]),
    handleJobSave() {
      this.$refs.details_card.getTemplateData().then(JobData => {
        this.handleSave(JobData);
      });
    },
    handleSave(data) {
      const { value: name } = Swal.fire({
        title: "Enter template name",
        input: "text",
        inputValue: this.getTemplate.name,
        showCancelButton: true,
        confirmButtonColor: "green",
        confirmButtonText: "Save",
        inputValidator: value => {
          if (!value) {
            return "Name is required";
          }
        }
      }).then(input => {
        if (input && !input.isDismissed) {
          this.update({ data: data, name: input.value });
        }
      });
    },
    handleAddNewShift(data) {
      this.addNewShift(data);
    }
  },
  computed: {
    ...mapGetters("SingleJobTemplateModule", ["getTemplate", "loading"])
  },
  watch: {
    getTemplate() {
      this.$refs.details_card.populateFieldsFromTemplate(
        JSON.parse(this.getTemplate.json_data).JobData
      );
    }
  },
  beforeMount() {
    if (!this.$can("job_template_access", "all")) {
      this.$router.push("/");
    }
    this.fetchSingle(this.$route.params.id);
  },
  beforeUnmount() {
    this.resetState();
  }
});
</script>
